export class Model {
  public page: Page = new Page();
  public contentGroups: Array<ContentGroup> = [];
  public loadingList: boolean = true;
  public loadingListProcessCount: number = 0;
  public get loadingListProcess(): boolean {
    return this.loadingListProcessCount !== 0;
  }
  public deleteCode: string = "";
  public deleteName: string = "";
  public deleteConfirmText: string = "";
  public deleteLoading: boolean = false;
}

export class ContentGroup {
  public constructor(public code: string, public name: string) {}
}

export class Page {
  public searchKeyword: string = "";
  public searchOrder: string = "new";
  public searchDetail: boolean = false;
  public viewCount: number = 10;
  public viewCounts: Array<number> = [5, 10, 20];
  public hitCount: number = 1;
  public pageCount: number = 1;
  public get pageS(): number {
    return 1;
  }
  public get pageE(): number {
    return Math.ceil(this.hitCount / this.viewCount);
  }
  public get viewS(): number {
    if (this.viewCount * (this.pageCount - 1) + 1 < this.hitCount) {
      return this.viewCount * (this.pageCount - 1) + 1;
    }
    return this.hitCount;
  }
  public get viewE(): number {
    if (this.viewCount * this.pageCount < this.hitCount) {
      return this.viewCount * this.pageCount;
    }
    return this.hitCount;
  }
  public get pages(): Array<PaginationItem> {
    const result = new Array<PaginationItem>();
    if (this.pageCount == this.pageS) {
      result.push(new PaginationItem(this.pageS, false, "<<"));
    } else {
      result.push(new PaginationItem(this.pageS, true, "<<"));
    }
    if (this.pageCount == this.pageS) {
      result.push(new PaginationItem(this.pageCount - 1, false, "<"));
    } else {
      result.push(new PaginationItem(this.pageCount - 1, true, "<"));
    }
    for (let i = 1; i <= this.pageE; i++) {
      if (this.pageCount == i) {
        result.push(new PaginationItem(i, false, i.toString()));
      } else if (Math.abs(this.pageCount - i) < 4) {
        result.push(new PaginationItem(i, true, i.toString()));
      }
    }
    if (this.pageCount == this.pageE) {
      result.push(new PaginationItem(this.pageCount + 1, false, ">"));
    } else {
      result.push(new PaginationItem(this.pageCount + 1, true, ">"));
    }
    if (this.pageCount == this.pageE) {
      result.push(new PaginationItem(this.pageE, false, ">>"));
    } else {
      result.push(new PaginationItem(this.pageE, true, ">>"));
    }
    return result;
  }
}

export class PaginationItem {
  public constructor(
    public page: number,
    public active: boolean,
    public text: string
  ) {}
}
